import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _union from 'lodash/union';
import _difference from 'lodash/difference';
import matchSorter from 'match-sorter';

import {
  FETCH_PROGRAM_HASH,
  SET_PROGRAM_SETTINGS_ERROR,
  SET_EXTERNAL_URL,
  REPLACE_UPDATED_PROGRAM_OBJECT,
  UPDATED_DATE_ERROR_OBJECT,
  ADD_NEW_TERM,
  SETTING_ERRORS,
  CLEAR_PROGRAM_SETTINGS_ERRORS,
  SHOW_PROGRAM_SETTINGS_LOADER,
  TOGGLE_STATUS,
  GET_AVAILABLE_TAGS,
  CLEAR_AVAILABLE_TAGS,
  AVAILABLE_TAGS_LOADING,
  HANDLE_CLEAR_STATE,
  TOGGLE_PROGRAM_PUBLISH,
  ALTERNATE_PROGRAM_COUNT,
  TOGGLE_UPDATE_STATES,
  SEARCH_TAGS,
  SEARCH_FORMS,
  CANCEL_PROGRAM_TERM,
  SAVE_NEW_ADMIN_DOCUMENT,
  DELETE_ADMIN_DOCUMENT,
  UPDATE_ADMIN_DOCUMENT,
  TOGGLE_DATE_CHANGE_WARNING_MODAL
} from '../actions/types';

const initialState = {
  programLoading: true,
  programObject: {},
  updatedProgramObject: {},
  dateErrorObject: {},
  invalidFields: [],
  hasErrors: false,
  errors: {},
  availableTags: [],
  availableForms: [],
  availableTagsPagination: {},
  availableTagsLoading: true,
  clearStates: true,
  status: null,
  alternateProgramCount: null,
  updateStates: false,
  originalTags: [],
  admin_program_attachment: [],
  showDateChangeWarningModal: false
};

export default function(state = initialState, action) {
  const cloneOfUpdatedProgramObject = _cloneDeep(state.updatedProgramObject);
  switch (action.type) {
    case FETCH_PROGRAM_HASH:
      return {
        ...state,
        programLoading: false,
        programObject: action.payload,
        hasErrors: false,
        errors: {},
        updatedProgramObject: {},
        invalidFields: [],
        updateStates: true,
        origingalForms: action.payload.client_forms,
      };
    case SET_PROGRAM_SETTINGS_ERROR:
      return {
        ...state,
        invalidFields: setErrors([...state.invalidFields], action.errorField, action.hasError),
      };
    case SET_EXTERNAL_URL:
      cloneOfUpdatedProgramObject.external_application_url = action.externalUrl;
      return {
        ...state,
        updatedProgramObject: cloneOfUpdatedProgramObject,
      };
    case ADD_NEW_TERM:
      return {
        ...state,
        programObject: action.payload,
      };
    case REPLACE_UPDATED_PROGRAM_OBJECT:
      return {
        ...state,
        updatedProgramObject: action.payload,
      };
    case UPDATED_DATE_ERROR_OBJECT:
      return {
        ...state,
        dateErrorObject: action.payload,
      };  
    case SETTING_ERRORS:
      return {
        ...state,
        hasErrors: true,
        errors: action.payload,
        programLoading: false,
        clearStates: false,
        updateStates: false,
      };
    case CLEAR_PROGRAM_SETTINGS_ERRORS:
      return {
        ...state,
        hasErrors: false,
        errors: {},
      };
    case SHOW_PROGRAM_SETTINGS_LOADER:
      return {
        ...state,
        programLoading: true,
      };
    case TOGGLE_STATUS:
      let obj = { ...state.programObject };
      obj.program.status = obj.program.status == 'published' ? 'draft' : 'published';
      let status = '';
      let hasErrors = false;
      let errors = '';

      if (action.payload) {
        status = _isEmpty(action.payload.status) ? '' : action.payload.status;
        hasErrors = _isEmpty(action.payload.errors) ? false : true;
        errors = _isEmpty(action.payload.errors) ? '' : action.payload.errors;
      }

      return {
        ...state,
        programObject: obj,
        status: status,
        hasErrors: hasErrors,
        errors: errors,
      };

    case GET_AVAILABLE_TAGS:
      return {
        ...state,
        availableTags: action.payload.tags,
        originalTags: action.payload.tags,
        availableTagsPagination: action.payload.pagination,
        availableTagsLoading: false,
      };
    case CLEAR_AVAILABLE_TAGS:
      return {
        ...state,
        availableTags: [],
        originalTags: [],
        availableTagsPagination: {},
        availableTagsLoading: true,
      };
    case AVAILABLE_TAGS_LOADING:
      return {
        ...state,
        availableTagsLoading: true,
      };
    case HANDLE_CLEAR_STATE:
      return {
        ...state,
        clearStates: action.payload,
      };
    case TOGGLE_PROGRAM_PUBLISH:
      return {
        ...state,
        status: action.payload.status,
        hasErrors: _isEmpty(action.payload.errors) ? false : true,
        errors: action.payload.errors,
        programLoading: false,
      };
    case ALTERNATE_PROGRAM_COUNT:
      let programObject = _cloneDeep(state.programObject);
      let range = programObject.ranges.filter(f => f.dummyId == action.dummyId)[0];
      range.alternateProgramCount = action.payload.alternateProgramsCount;
      return {
        ...state,
        programObject: programObject,
      };
    case TOGGLE_UPDATE_STATES:
      return {
        ...state,
        updateStates: action.payload,
      };
    case SEARCH_TAGS:
      return {
        ...state,
        availableTags: matchSorter([...state.originalTags], action.payload, {
          keys: ['name'],
        }),
      };
    case SEARCH_FORMS:
      return {
        ...state,
        availableForms: matchSorter([...state.programObject.client_forms], action.payload, { keys: ['title'] }),
      };
    case CANCEL_PROGRAM_TERM:
      return {
        ...state,
      };
    case SAVE_NEW_ADMIN_DOCUMENT:
      return {
        ...state,
        admin_program_attachment: action.payload,
      };
    case DELETE_ADMIN_DOCUMENT:
      return {
        ...state,
        admin_program_attachment: action.payload,
      };
    case UPDATE_ADMIN_DOCUMENT:
      return {
        ...state,
        admin_program_attachment: action.payload,
      };
    case TOGGLE_DATE_CHANGE_WARNING_MODAL:
      return {
        ...state,
        showDateChangeWarningModal: action.payload
      }
    
    default:
      return state;
  }
}

const setErrors = (invalidFields, field, hasError) => {
  return hasError ? _union([field], invalidFields) : _difference(invalidFields, [field]);
};
