import moment from 'moment-timezone';
import _filter from 'lodash/filter';
import _groupBy from 'lodash/groupBy';
import _orderBy from 'lodash/orderBy';
import _find from 'lodash/find';
import userId from '../shared/helpers/userId';
import {
  GET_ALL_PLANS,
  GET_PLAN_REQUEST,
  GET_PLAN_SUCCESS,
  GET_PLAN_FAILURE,
  ADDPATCH_PLAN_REQUEST,
  ADDPATCH_PLAN_SUCCESS,
  ADDPATCH_PLAN_FAILURE,
  ADDPATCH_PLAN_CLEAR,
  ADDPATCH_DETAIL_REQUEST,
  ADDPATCH_DETAIL_SUCCESS,
  ADDPATCH_DETAIL_FAILURE,
  ADDPATCHUSER_PLAN_REQUEST,
  ADDPATCHUSER_PLAN_SUCCESS,
  ADDPATCHUSER_PLAN_FAILURE,
  ADDPATCHUSER_PLAN_CLEAR,
  GET_ALL_TRAVELERS,
  ADD_TRAVELERS_REQUEST,
  ADD_TRAVELERS_SUCCESS,
  ADD_TRAVELERS_FAILURE,
  ADD_TRAVELERS_CLEAR,
  ADD_DETAIL_TO_TRAVELER_REQUEST,
  ADD_DETAIL_TO_TRAVELER_SUCCESS,
  ADD_DETAIL_TO_TRAVELER_FAILURE,
  ADD_DETAIL_TO_TRAVELER_CLEAR,
  FETCH_PROGRAM_LINK,
  PATCH_DETAIL_FROM_TRAVELER_REQUEST,
  PATCH_DETAIL_FROM_TRAVELER_SUCCESS,
  PATCH_DETAIL_FROM_TRAVELER_FAILURE,
  PATCH_DETAIL_FROM_TRAVELER_CLEAR,
  PATCH_PLAN_USER_REQUEST,
  PATCH_PLAN_USER_SUCCESS,
  PATCH_PLAN_USER_FAILURE,
  PATCH_PLAN_USER_CLEAR,
  SEND_EMAIL_TO_TRAVELERS,
  SEND_EMAIL_TO_TRAVELERS_FAILURE,
  SEND_EMAIL_TO_TRAVELERS_CLEAR,
  SEND_EMAIL_TO_TRAVELERS_SUCCESS,
  BATCH_UPDATE_PLAN_REGISTRATION_STATUS,
  BATCH_UPDATE_PLAN_REGISTRATION_STATUS_FAILURE,
  BATCH_UPDATE_PLAN_REGISTRATION_STATUS_CLEAR,
  BATCH_UPDATE_PLAN_REGISTRATION_STATUS_SUCCESS,
  BATCH_UPDATE_PLAN_STATUS,
  BATCH_UPDATE_PLAN_STATUS_FAILURE,
  BATCH_UPDATE_PLAN_STATUS_CLEAR,
  BATCH_UPDATE_PLAN_STATUS_SUCCESS,
  FETCH_TRANSPORTATION_DETAILS,
  FETCH_HOUSING_DETAILS,
  FETCH_ACTIVITY_DETAILS,
  CREATE_PLAN_TERM_REQUEST,
  CREATE_PLAN_TERM_SUCCESS,
  CREATE_PLAN_TERM_FAILURE,
} from '../actions/types';

const initialState = {
  allPlans: {
    plans: null,
    upcoming_plans: null,
    onsite_plans: null,
    past_plans: null,
    cancelled_plans: null,
    group_plans: null,
    loading: true,
    error: null,
  },
  addPatchPlan: {
    plan: null,
    loading: false,
    error: null,
  },
  plan: {
    plan: null,
    program: null,
    loading: false,
    error: false,
    errorMsg: null,
    me: null,
    traveler_itinerary_byType: null,
    traveler_itinerary_byDate: null,
    group_itinerary_byType: null,
    group_itinerary_byDate: null,
    travelers: null,
  },
  addDetail: {
    detail: null,
    loading: false,
    error: null,
  },
  addPatchUserPlan: {
    user: null,
    loading: false,
    error: null,
  },
  allTravelers: {
    travelers: null,
    loading: true,
    error: null,
  },
  addTravelers: {
    travelersAdded: null,
    loading: true,
    error: null,
  },
  addDetailToTraveler: {
    data: null,
    loading: false,
    error: null,
  },
  removeGroupFromTraveler: {
    data: null,
    loading: false,
    error: null,
  },
  availablePrograms: {
    programs: null,
    loading: true,
    error: null,
  },
  patchPlanUser: {
    data: null,
    loading: false,
    error: null,
  },
  sendSafecheckMessage: {
    data: null,
    loading: false,
    error: null,
  },
  sendEmailToTravelers: {
    data: null,
    loading: false,
    error: null,
  },
  batchUpdatePlanRegistrationStatus: {
    data: null,
    loading: false,
    error: null,
  },
  batchUpdatePlanStatus: {
    data: null,
    loading: false,
    error: null,
  },
  transportationDetails: {
    data: null,
    loading: false,
    error: null,
  },
  housingDetails: {
    data: null,
    loading: false,
    error: null,
  },
  activityDetails: {
    data: null,
    loading: false,
    error: null,
  },

  createPlanTerm: {
    loading: false,
    created: [],
    failures: [],
    message: '',
    error: '',
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PLANS:
      /*
      Notes on plan and registration status codes.
        plan statuses:
        1 - Registered
        2 - Pending Approval
        3 - Approved
        4 - Not Approved

        registration statuses
        1 - Open
        2 - Closed
        3 - Cancelled
        4 - Complete
       */
      const planArray = action.payload.sort((a, b) => {
        const dateA = new Date(a.start_date);
        const dateB = new Date(b.start_date);

        return dateA - dateB; // Ascending order
      });

      const upcomingPlans = _filter(planArray, function(plan) {
        const getDate = moment(plan.start_date).format('YYYY-MM-DD');

        return !plan.joinable && !plan.removed && moment().isBefore(getDate, 'day');
      });

      const onsitePlans = _filter(planArray, function(plan) {
        const getStartDate = moment(plan.start_date).format('YYYY-MM-DD');
        const getEndDate = moment(plan.end_date).format('YYYY-MM-DD');

        return !plan.joinable && !plan.removed && moment().isBetween(getStartDate, getEndDate, 'day', '[]');
      });

      const pastPlans = _filter(planArray, function(plan) {
        const getDate = moment(plan.end_date).format('YYYY-MM-DD');

        return !plan.joinable && !plan.removed && moment().isAfter(getDate, 'day');
      });

      const cancelledPlans = _filter(planArray, function(plan) {
        return plan.joinable && plan.removed;
      });

      const groupPlans = _filter(planArray, function(plan) {
        const getDate = moment(plan.end_date).format('YYYY-MM-DD');
        return plan.joinable && moment().isBefore(getDate, 'day');
      });

      return {
        ...state,
        allPlans: {
          plans: planArray,
          upcoming_plans: upcomingPlans,
          onsite_plans: onsitePlans,
          past_plans: pastPlans,
          cancelled_plans: cancelledPlans,
          group_plans: groupPlans,
          loading: false,
          error: null,
        },
      };
    case ADDPATCH_PLAN_REQUEST:
      return {
        ...state,
        addPatchPlan: {
          plan: null,
          loading: true,
          error: null,
        },
      };
    case ADDPATCH_PLAN_SUCCESS:
      return {
        ...state,
        addPatchPlan: {
          plan: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case ADDPATCH_PLAN_FAILURE:
      return {
        ...state,
        addPatchPlan: {
          plan: null,
          loading: false,
          error: 'There was a problem saving your travel plan.  Please try again.',
        },
      };
    case ADDPATCH_PLAN_CLEAR:
      return {
        ...state,
        addPatchPlan: {
          plan: null,
          loading: false,
          error: null,
        },
      };
    case GET_PLAN_REQUEST:
      return {
        plan: {
          plan: null,
          program: null,
          loading: true,
          error: false,
          errorMsg: null,
          me: null,
          traveler_itinerary_byType: null,
          traveler_itinerary_byDate: null,
          group_itinerary_byType: null,
          group_itinerary_byDate: null,
          travelers: null,
        },
      };
    case GET_PLAN_SUCCESS:
      let traveler_itinerary_byDate = null;
      let me = null;
      let traveler_itinerary_byType = null;
      let group_itinerary_byType = null;
      let group_itinerary_byDate = null;
      let travelers = null;

      const planUser = _find(action.payload.data.attributes.travelers, traveler => {
        return traveler.id.toString() === userId.toString();
      });

      if (!!planUser && !!planUser.itinerary) {
        me = planUser;
        const itineraryItems = planUser.itinerary;
        let dateMap = itineraryItems.map(item => {
          return {
            ...item,
            startGroupDate:
              item.type === 'plan_activity'
                ? item.start_date
                : item.type === 'plan_transportation'
                ? item.departure_date
                : item.arrival_date,
          };
        });
        dateMap = _orderBy(dateMap, ['startGroupDate'], ['asc']);

        traveler_itinerary_byDate = _groupBy(dateMap, item => item.startGroupDate);
        traveler_itinerary_byType = _groupBy(itineraryItems, item => item.type);
      }

      if (action.payload.data.attributes.group_itinerary) {
        const groupItems = action.payload.data.attributes.group_itinerary;
        let dateMap = groupItems.map(item => {
          return {
            ...item,
            startGroupDate:
              item.type === 'plan_activity'
                ? item.start_date
                : item.type === 'plan_transportation'
                ? item.departure_date
                : item.arrival_date,
          };
        });
        dateMap = _orderBy(dateMap, ['startGroupDate'], ['asc']);

        group_itinerary_byDate = _groupBy(dateMap, item => item.startGroupDate);
        group_itinerary_byType = _groupBy(groupItems, item => item.type);
      }

      if (!!action.payload.data.attributes.travelers) {
        travelers = action.payload.data.attributes.travelers;
      }

      return {
        plan: {
          plan: action.payload.data,
          loading: false,
          error: false,
          errorMsg: null,
          me: me,
          traveler_itinerary_byType: traveler_itinerary_byType,
          traveler_itinerary_byDate: traveler_itinerary_byDate,
          group_itinerary_byType: group_itinerary_byType,
          group_itinerary_byDate: group_itinerary_byDate,
          travelers: travelers,
        },
      };
    case GET_PLAN_FAILURE:
      return {
        plan: {
          plan: null,
          loading: false,
          error: true,
          errorMsg: 'There was a problem loading the selected plan. Please try again later.',
          me: null,
          traveler_itinerary_byType: null,
          traveler_itinerary_byDate: null,
          group_itinerary_byType: null,
          group_itinerary_byDate: null,
          travelers: null,
        },
      };
    case ADDPATCH_DETAIL_REQUEST:
      return {
        ...state,
        addDetail: {
          detail: null,
          loading: true,
          error: null,
        },
      };
    case ADDPATCH_DETAIL_SUCCESS:
      return {
        ...state,
        addDetail: {
          detail: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case ADDPATCH_DETAIL_FAILURE:
      return {
        ...state,
        addDetail: {
          detail: null,
          loading: false,
          error: 'There was a problem saving your details.  Please try again later.',
        },
      };
    case ADDPATCHUSER_PLAN_REQUEST:
      return {
        ...state,
        addPatchUserPlan: {
          user: null,
          loading: true,
          error: null,
        },
      };
    case ADDPATCHUSER_PLAN_SUCCESS:
      return {
        ...state,
        addPatchUserPlan: {
          user: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case ADDPATCHUSER_PLAN_FAILURE:
      return {
        ...state,
        addPatchUserPlan: {
          user: null,
          loading: false,
          error: 'There was a problem adding traveler to plan.  Please try again later.',
        },
      };
    case ADDPATCHUSER_PLAN_CLEAR:
      return {
        ...state,
        addPatchUserPlan: {
          user: null,
          loading: false,
          error: null,
        },
      };
    case GET_ALL_TRAVELERS:
      return {
        ...state,
        allTravelers: {
          travelers: action.payload,
          loading: false,
          error: null,
        },
      };
    case ADD_TRAVELERS_REQUEST:
      return {
        ...state,
        addTravelers: {
          travelersAdded: null,
          loading: true,
          error: null,
        },
      };
    case ADD_TRAVELERS_SUCCESS:
      return {
        ...state,
        addTravelers: {
          travelersAdded: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case ADD_TRAVELERS_FAILURE:
      return {
        ...state,
        addTravelers: {
          travelersAdded: null,
          loading: false,
          error: 'There was a problem adding traveler to plan.  Please try again later.',
        },
      };
    case ADD_TRAVELERS_CLEAR:
      return {
        ...state,
        addTravelers: {
          travelersAdded: null,
          loading: false,
          error: null,
        },
      };
    case ADD_DETAIL_TO_TRAVELER_REQUEST:
      return {
        ...state,
        addDetailToTraveler: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case ADD_DETAIL_TO_TRAVELER_SUCCESS:
      return {
        ...state,
        addDetailToTraveler: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case ADD_DETAIL_TO_TRAVELER_FAILURE:
      return {
        ...state,
        addDetailToTraveler: {
          data: null,
          loading: false,
          error: 'There was a problem adding details to the plan.  Please try again later.',
        },
      };
    case ADD_DETAIL_TO_TRAVELER_CLEAR:
      return {
        ...state,
        addDetailToTraveler: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case PATCH_DETAIL_FROM_TRAVELER_REQUEST:
      return {
        ...state,
        patchDetailFromTraveler: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case PATCH_DETAIL_FROM_TRAVELER_SUCCESS:
      return {
        ...state,
        patchDetailFromTraveler: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case PATCH_DETAIL_FROM_TRAVELER_FAILURE:
      return {
        ...state,
        patchDetailFromTraveler: {
          data: null,
          loading: false,
          error: 'There was a problem removing details from the plan.  Please try again later.',
        },
      };
    case PATCH_DETAIL_FROM_TRAVELER_CLEAR:
      return {
        ...state,
        patchDetailFromTraveler: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case PATCH_PLAN_USER_REQUEST:
      return {
        ...state,
        patchPlanUser: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case PATCH_PLAN_USER_SUCCESS:
      return {
        ...state,
        patchPlanUser: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case PATCH_PLAN_USER_FAILURE:
      return {
        ...state,
        patchPlanUser: {
          data: null,
          loading: false,
          error: 'There was a problem updating the plan user.  Please try again later.',
        },
      };
    case PATCH_PLAN_USER_CLEAR:
      return {
        ...state,
        patchPlanUser: {
          data: null,
          loading: false,
          error: null,
        },
      };

    case FETCH_PROGRAM_LINK:
      return {
        ...state,
        availablePrograms: {
          programs: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case SEND_EMAIL_TO_TRAVELERS:
      return {
        ...state,
        sendEmailToTravelers: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case SEND_EMAIL_TO_TRAVELERS_SUCCESS:
      return {
        ...state,
        sendEmailToTravelers: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case SEND_EMAIL_TO_TRAVELERS_FAILURE:
      return {
        ...state,
        sendEmailToTravelers: {
          data: null,
          loading: false,
          error: 'There was a problem emailing the plan user.  Please try again later.',
        },
      };
    case SEND_EMAIL_TO_TRAVELERS_CLEAR:
      return {
        ...state,
        sendEmailToTravelers: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case BATCH_UPDATE_PLAN_REGISTRATION_STATUS:
      return {
        ...state,
        batchUpdatePlanRegistrationStatus: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case BATCH_UPDATE_PLAN_REGISTRATION_STATUS_SUCCESS:
      return {
        ...state,
        batchUpdatePlanRegistrationStatus: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case BATCH_UPDATE_PLAN_REGISTRATION_STATUS_FAILURE:
      return {
        ...state,
        batchUpdatePlanRegistrationStatus: {
          data: null,
          loading: false,
          error: 'There was a problem updating the plan registration status.  Please try again later.',
        },
      };
    case BATCH_UPDATE_PLAN_REGISTRATION_STATUS_CLEAR:
      return {
        ...state,
        batchUpdatePlanRegistrationStatus: {
          data: null,
          loading: false,
          error: null,
        },
      };

    case BATCH_UPDATE_PLAN_STATUS:
      return {
        ...state,
        batchUpdatePlanStatus: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case BATCH_UPDATE_PLAN_STATUS_SUCCESS:
      return {
        ...state,
        batchUpdatePlanStatus: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case BATCH_UPDATE_PLAN_STATUS_FAILURE:
      return {
        ...state,
        batchUpdatePlanStatus: {
          data: null,
          loading: false,
          error: 'There was a problem updating the plan status.  Please try again later.',
        },
      };
    case BATCH_UPDATE_PLAN_STATUS_CLEAR:
      return {
        ...state,
        batchUpdatePlanStatus: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case FETCH_TRANSPORTATION_DETAILS:
      const transportationDataSet = action?.version ? action.payload : action.payload.data;
      return {
        ...state,
        transportationDetails: {
          data: transportationDataSet,
          loading: false,
          error: null,
        },
      };
    case FETCH_HOUSING_DETAILS:
      const houseingDataSet = action?.version ? action.payload : action.payload.data;
      return {
        ...state,
        housingDetails: {
          data: houseingDataSet,
          loading: false,
          error: null,
        },
      };
    case FETCH_ACTIVITY_DETAILS:
      const activityDataSet = action?.version ? action.payload : action.payload.data;
      return {
        ...state,
        activityDetails: {
          data: activityDataSet,
          loading: false,
          error: null,
        },
      };

    case CREATE_PLAN_TERM_REQUEST:
      return {
        ...state,
        createPlanTerm: {
          loading: true,
          created: [],
          failures: [],
          message: '',
          error: '',
        },
      };

    case CREATE_PLAN_TERM_SUCCESS:
      return {
        ...state,
        createPlanTerm: {
          loading: false,
          created: action?.payload?.created,
          failures: action?.payload?.failures,
          message: `${action?.payload?.created?.length} Travel Plan(s) Created!`,
          error: '',
        },
      };

    case CREATE_PLAN_TERM_FAILURE:
      return {
        ...state,
        createPlanTerm: {
          loading: false,
          created: [],
          failures: [],
          message: '',
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
