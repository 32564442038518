import {
  FETCH_INTAKE_FORM,
  ADD_INTAKE_FORM_GOAL,
  ADD_INTAKE_FORM_PROFILE,
  ADD_INTAKE_FORM_ADVISING,
  ADD_INTAKE_FORM_COMPLETE,
  ADD_INTAKE_FORM_PASSPORT,
  ADD_INTAKE_FORM_FINANCIAL_AID,
  ADD_INTAKE_FORM_TRAVELER_TYPE,
  ADD_INTAKE_FORM_FINANCIAL_INFO,
  ADD_INTAKE_FORM_HOME_INSTITUTION,
  ADD_INTAKE_FORM_TRAVEL_EXPERIENCE,
  ADD_INTAKE_FORM_PARTICIPATION_CERTAINTY,
  ADD_INTAKE_FORM_COUNTRIES_OF_CITIZENSHIP,
  ADD_INTAKE_FORM_PRIMARY_MAJOR,
  ADD_INTAKE_FORM_PRIMARY_MINOR,
  ADD_INTAKE_FORM_HONORS_STATUS,
  ADD_INTAKE_FORM_PRONOUN,
  ADD_INTAKE_FORM_YEAR_IN_SCHOOL,
  ADD_INTAKE_FORM_GRAD_YEAR,
  ADD_INTAKE_FORM_CUMULATIVE_GPA,
  ADD_INTAKE_FORM_VETERAN_STATUS,
  ADD_INTAKE_FORM_FIRST_GENERATION_STATUS,
  ADMIN_INTAKE_FORM_VALUES,
  CLEAR_ADMIN_INTAKE_FORM_VALUES,
  SHOW_INTAKE_PREVIEW,
} from '../actions/types';

const initialState = {
  advising: [],
  readonly: [],
  last_name: '',
  first_name: '',
  school_name: '',
  signup_date: '',
  travel_goals: [],
  traveler_type: '',
  signup_source: '',
  financial_info: [],
  ssoTraveler: false,
  intake_complete: null,
  travel_experience: [],
  travel_certainty: null,
  countries_of_citizenship: [],
  receives_federal_financial_aid: null,
  passport: { status: null, alpha2: null },
  intakeQuestions: [],
  primary_major: "",
  primary_minor: "",
  honors_status: "",
  pronoun: "",
  year_in_school: "",
  grad_year: "",
  cumulative_gpa: "",
  veteran_status: "",
  first_generation_status: "",
  adminIntakeFormData: {},
  showIntakePreviewModal : false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_INTAKE_FORM:
      return {
        ...state,
        advising: action.payload.advising,
        passport: action.payload.passport,
        readonly: action.payload.readonly,
        last_name: action.payload.last_name,
        first_name: action.payload.first_name,
        school_name: action.payload.school_name,
        signup_date: action.payload.signup_date,
        ssoTraveler: action.payload.sso_traveler,
        travel_goals: action.payload.travel_goals,
        signup_source: action.payload.signup_source,
        traveler_type: action.payload.traveler_type,
        financial_info: action.payload.financial_info,
        travel_certainty: action.payload.travel_certainty,
        travel_experience: action.payload.travel_experience,
        countries_of_citizenship: action.payload.countries_of_citizenship,
        receives_federal_financial_aid: action.payload.receives_federal_financial_aid,
        primary_major: action.payload.primary_major,
        primary_minor: action.payload.primary_minor,
        honors_status: action.payload.honors_status,
        pronoun: action.payload.pronoun,
        year_in_school: action.payload.year_in_school,
        grad_year: action.payload.grad_year,
        cumulative_gpa: action.payload.cumulative_gpa,
        veteran_status: action.payload.veteran_status,
        first_generation_status: action.payload.first_generation_status,
        intakeQuestions: action.payload.intake_questions,
      };
    case ADD_INTAKE_FORM_PROFILE:
      return {
        ...state,
        last_name: action.payload.last_name,
        first_name: action.payload.first_name,
      };
    case ADD_INTAKE_FORM_COUNTRIES_OF_CITIZENSHIP:
      return {
        ...state,
        countries_of_citizenship: action.payload.countries_of_citizenship,
      };
    case ADD_INTAKE_FORM_TRAVELER_TYPE:
      return {
        ...state,
        traveler_type: action.payload,
      };
    case ADD_INTAKE_FORM_HOME_INSTITUTION:
      return {
        ...state,
        school_name: action.payload.school_name,
      };
    case ADD_INTAKE_FORM_ADVISING:
      return {
        ...state,
        advising: action.payload,
      };
    case ADD_INTAKE_FORM_FINANCIAL_INFO:
      return {
        ...state,
        financial_info: action.payload,
      };
    case ADD_INTAKE_FORM_FINANCIAL_AID:
      return {
        ...state,
        receives_federal_financial_aid: action.payload,
      };
    case ADD_INTAKE_FORM_GOAL:
      return {
        ...state,
        travel_goals: action.payload,
      };
    case ADD_INTAKE_FORM_TRAVEL_EXPERIENCE:
      return {
        ...state,
        travel_experience: action.payload,
      };
    case ADD_INTAKE_FORM_PASSPORT:
      return {
        ...state,
        passport: action.payload,
      };
    case ADD_INTAKE_FORM_PARTICIPATION_CERTAINTY:
      return {
        ...state,
        intake_complete: action.payload.intake_complete,
        travel_certainty: action.payload.travel_certainty,
      };
    case ADD_INTAKE_FORM_COMPLETE:
      return {
        ...state,
        intake_complete: action.payload,
      };
    case ADD_INTAKE_FORM_HONORS_STATUS:
      return {
        ...state,
        honors_status: action.payload.honors_status,
      };

      case ADD_INTAKE_FORM_PRIMARY_MAJOR:
        return {
          ...state,
          primary_major: action.payload.primary_major,
        };
        
        case ADD_INTAKE_FORM_PRIMARY_MINOR:
      return {
        ...state,
        primary_minor: action.payload.primary_minor,
      };

    case ADD_INTAKE_FORM_PRONOUN:
      return {
        ...state,
        pronoun: action.payload.pronoun,
      };  

    case ADD_INTAKE_FORM_YEAR_IN_SCHOOL:
      return {
        ...state,
        year_in_school: action.payload.year_in_school,
      };  
    
    case ADD_INTAKE_FORM_GRAD_YEAR:
      return {
        ...state,
        grad_year: action.payload.grad_year,
      };   

    case ADD_INTAKE_FORM_CUMULATIVE_GPA:
      return {
        ...state,
        cumulative_gpa: action.payload.cumulative_gpa,
      };
    
    case ADD_INTAKE_FORM_VETERAN_STATUS:
      return {
        ...state,
        veteran_status: action.payload.veteran_status,
      };  
    case ADD_INTAKE_FORM_FIRST_GENERATION_STATUS:
      return {
        ...state,
        first_generation_status: action.payload.first_generation_status,
      };  


    case ADMIN_INTAKE_FORM_VALUES:
      return {
        ...state,
        adminIntakeFormData: {
          ...state.adminIntakeFormData,
          [action.payload.step]: action.payload.data,
        },
      };  
    case CLEAR_ADMIN_INTAKE_FORM_VALUES:
      return {
        ...state,
        adminIntakeFormData: {},
      };  
    case SHOW_INTAKE_PREVIEW:
      return {
        ...state,
        showIntakePreviewModal: action.payload,
      };  
    default:
      return state;
  }
}
